<template>
	<div class="checkbox-view">
		<div class="title-value">
			<div class="num">{{itemIndex+1}}、</div>
			<div class="value">
				{{itemData.TopicDescription}}
				<span class="icon-star" v-if="itemData.IsMustAnswer">*</span>
			</div>
			
		</div>
		<div class="list">
			<div class="item" v-for="(item, index) in itemData.QuestionnaireTopicOptionDtoList" :key="index">
				<div class="check"></div>
				<div class="item-txt">
					<div>{{item.OptionDescription}}</div>
					<div class="input" v-if="item.IsOther"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
			};
		},
		computed: {
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
		}
	}
</script>

<style lang="less" scoped>
.checkbox-view{
	
	padding: 13px 0 20px;
	margin: 0 15px;
	font-size: 14px;
	border-bottom: 1px solid #eee;
	
	.title-value{
		display: flex;
		margin-bottom: 5px;
		
		.num{
			flex: 0 0 auto;
		}
		.value{
			flex: 1 1 auto;
			line-height: 18px;
			// min-height: 24px;
			
			.icon-star{
				padding-left: 2px;
				color: #e34242;
				display: inline-block;
				font-size: 20px;
				height: 10px;
				overflow: hidden;
			}
		}
	}
	
	.list{
		padding-left: 22px;
		padding-top: 5px;
		.item{
			display: flex;
			margin-bottom: 10px;
			
			.check{
				flex: 0 0 auto;
				width: 18px;
				height: 18px;
				border-radius: 3px;
				border: 1px solid #ddd;
			}
			.item-txt{
				flex: 1 1 auto;
				line-height: 18px;
				margin-left: 5px;
			}
			.input{
				width: 100%;
				height: 30px;
				border: 1px solid #eee;
				border-radius: 3px;
				margin-top: 10px;
			}
		}
	}
}
</style>
