<template>
	<div class="phone-number-view">
		<div class="title-value">
			<div class="num">{{itemIndex+1}}、</div>
			<div class="value">
				{{itemData.TopicDescription}}
				<span class="icon-star" v-if="itemData.IsMustAnswer">*</span>
			</div>
		</div>
		
		<div class="input"></div>
		
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
			};
		},
		computed: {
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
		}
	}
</script>

<style lang="less" scoped>
.phone-number-view{
	
	padding: 13px 0 20px;
	margin: 0 15px;
	font-size: 14px;
	border-bottom: 1px solid #eee;
	
	.title-value{
		display: flex;
		margin-bottom: 15px;
		
		.num{
			flex: 0 0 auto;
		}
		.value{
			flex: 1 1 auto;
			line-height: 18px;
			// min-height: 24px;
			
			.icon-star{
				padding-left: 4px;
				color: #e34242;
				display: inline-block;
				font-size: 20px;
				height: 10px;
				overflow: hidden;
			}
		}
	}
	
	.input{
		width: 300px;
		height: 30px;
		border: 1px solid #ddd;
		border-radius: 4px;
		margin-left: 22px;
	}
	
}
</style>
