<template>
	<div class="score-ten-module">
		<div class="title">
			<div class="num">{{itemIndex+1}}.</div>
			<div class="input"><el-input type="textarea" :rows="1" :autosize="true" v-model="itemData.TopicDescription" maxlength="500"></el-input></div>
			<div class="control"><div style = "color:#409eff" class="drag">拖拽排序</div><div style = "color:rgb:(245,108,108)" class="delete" @click="handleDelete">删除</div></div>
		</div>
		
		<div class="score-list">
			<div class="scrore-item" v-for="i in 10" :key="i">{{i}}</div>
		</div>
		<div class="score-description" v-if="itemData.showDescrition">
			<div class="left">{{itemData.UserDefinedDescriptionStart}}</div>
			<div class="right">{{itemData.UserDefinedDescriptionEnd}}</div>
		</div>
		<div class="edit-desc" v-if="itemData.showDescrition">
			<div class="edit-title" style = "color:#409eff">自定义描述：（最多10个字）</div>
			<div class="edit-desc-input">
				<el-input type="text" size="mini" maxlength="10" style="width: 160px;" v-model="itemData.UserDefinedDescriptionStart"></el-input>
				-
				<el-input type="text" size="mini" maxlength="10" style="width: 160px;" v-model="itemData.UserDefinedDescriptionEnd"></el-input>
			</div>
		</div>
		
		<div class="control-wraper">
			<div class="btn" style = "color:#409eff">
				自定义描述
				<el-switch
				  v-model="itemData.showDescrition">
				</el-switch>
			</div>
			<div class="btn" style = "color:#409eff">
				此题必答
				<el-switch
				  v-model="itemData.IsMustAnswer">
				</el-switch>
			</div>
		</div>
		
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
				
			};
		},
		computed: {
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
			handleDelete(){
				this.$emit('delete', {item: this.itemData, index: this.itemIndex})
			},
		}
	}
</script>

<style lang="less">
.score-ten-module{
	border: 1px solid #DCDFE6;
	border-radius: 4px;
	padding: 30px 30px 20px;
	font-size: 14px;
	
	.title{
		display: flex;
		align-items: center;
		overflow: hidden;
		
		.num{
			flex: 0 0 auto;
			margin-right: 5px;
		}
		.input{
			flex: 1 1 auto;
			margin-right: 20px;
			border: 1px dashed #ddd;
			min-height: 30px;
			padding-top: 4px;
			
			textarea{
				height: 36px;
				border: none;
				padding: 0 10px;
				width: 100%;
				font-size: 14px;
				box-sizing: border-box;
				outline:none;
			}
			
		}
		.control{
			flex: 0 0 auto;
			white-space: nowrap;
			display: flex;
			
			.drag{
				color: #60aeff;
				margin-right: 10px;
			}
			.delete{
				color: #f36868;
				cursor: pointer;
			}
		}
	}

	.score-list{
		border: 1px solid #ddd;
		border-radius: 20px;
		height: 30px;
		width: 350px;
		margin-top: 20px;
		margin-left: 20px;
		
		display: flex;
		align-items: center;
		
		.scrore-item{
			width: 35px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			border-left: 1px solid #ddd;
			box-sizing: border-box;
			
			&:first-child{
				border-left: none;
			}
		}
	}
	
	.score-description{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 5px;
		margin-left: 20px;
		color: #999;
		font-size: 12px;
		width: 350px;
		padding: 0 5px;
		box-sizing: border-box;
	}
	.edit-desc{
		margin-top: 20px;
		margin-left: 20px;
		
		.edit-title{
			margin-bottom: 8px;
		}
	}
	
	
	.control-wraper{
		display: flex;
		padding-top: 20px;
		margin-left: 15px;
		
		.btn{
			color: #60aeff;
			margin-right: 30px;
			cursor: pointer;
		}
	}
	
	.pop-content{
		.pop-title{
			font-size: 15px;
		}
		.pop-tip{
			font-size: 12px;
			color: #999;
			margin-top: 10px;
		}
	}
	
}
</style>
