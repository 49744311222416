<template>
	<div class="radio-module">
		<div class="title">
			<div class="num">{{itemIndex+1}}.</div>
			<div class="input"><el-input type="textarea" :rows="1" :autosize="true" v-model="itemData.TopicDescription" maxlength="500"></el-input></div>
			<div class="control"><div class="drag" style = "color:#409eff">拖拽排序</div><div style = "color:rgb(245,108,108)" class="delete" @click="handleDelete">删除</div></div>
		</div>
		<div class="check-list">
			<div v-for="(item, index) in itemData.QuestionnaireTopicOptionDtoList" :key="index">
				<div class="check-item" v-if="!item.IsOther">
					<div class="check"></div>
					<div class="input">
						<el-input type="textarea" :rows="1" :autosize="true" class="name" v-model="item.OptionDescription" maxlength="500"></el-input>
					</div>
					<div class="delete-item" v-if="itemData.QuestionnaireTopicOptionDtoList.length > 1" @click="handleDeleteItem(index)">
						<i class="el-icon-delete"></i>
					</div>
				</div>
				<div class="check-item-other" v-if="item.IsOther">
					<div class="check-item">
						<div class="check"></div>
						<div class="input">
							<el-input type="textarea" :rows="1" :autosize="true" class="name" v-model="item.OptionDescription" maxlength="500"></el-input>
						</div>
						<div class="delete-item" v-if="itemData.QuestionnaireTopicOptionDtoList.length > 1" @click="handleDeleteItem(index)">
							<i class="el-icon-delete"></i>
						</div>
					</div>
					<div style="margin-left: 52px;margin-top: 5px;margin-right: 113px;"><el-input type="text"></el-input></div>
				</div>
			</div>
			
			
		</div>
		
		<div class="control-wraper">
			<el-popover
			  placement="right"
			  width="400"
			  v-model="visiblePop"
			  trigger="click">
				<div class="pop-content">
					<div class="pop-title" style="font-size: 15px;color:#409eff" >添加选项</div>
					<div class="pop-tip" style="font-size: 12px;color: #999;margin-top: 5px;">每行代表一个选项,可添加多个选项</div>
					<el-input type="textarea" v-model="popText" resize="none" :rows="10" style="margin-top: 10px;"></el-input>
					<div class="pop-btn" style="text-align: right;margin-top: 15px;">
						<el-button size="mini" @click="visiblePop=false">取消</el-button>
						<el-button size="mini" type="primary" @click="handleSureAdd">确认</el-button>
					</div>
				</div>
				<div class="btn" style = "color:#409eff" slot="reference" @click="showPop">添加选项</div>
			</el-popover>
			
			<div class="btn" style = "color:#409eff" @click="addOther" v-if="showOther">添加[其它]项</div>
			<div class="btn" style = "color:#409eff">
				此题必答
				<el-switch
				  v-model="itemData.IsMustAnswer">
				</el-switch>
			</div>
		</div>
		
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
				popText: '',
				visiblePop: false
			};
		},
		computed: {
			showOther(){
				var list = this.itemData.QuestionnaireTopicOptionDtoList.filter(item=>{
					return item.IsOther;
				})
				return list.length === 0
			}
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
			handleDelete(){
				this.$emit('delete', {item: this.itemData, index: this.itemIndex})
			},
			showPop(){
				// var list = []
				// this.itemData.QuestionnaireTopicOptionDtoList.map(item=>{
				// 	if (!item.IsOther){
				// 		list.push(item.OptionDescription)
				// 	}
				// })
				// this.popText = list.join('\n')
				this.popText = '';
			},
			handleSureAdd(){
				console.log(this.popText.split('\n'))
				var list = this.popText.split('\n');
				var dt = list.filter(item=>{
					return item.trim() !== '';
				})
				
				
				if (!dt.length){
					this.$message.error('请至少添加一项')
				}else{
					var array = []
					dt.map(item=>{
						var str = item.trim();
						
						if (str !== '') {
							if (str.length > 500) {
								str = str.substr(0, 500)
							}
							array.push({
								Id: 0,
								OptionDescription: str,
								IsOther: false,
								Sort: 0
							})
						}
					})
					
					var obj = this.itemData.QuestionnaireTopicOptionDtoList.filter(item=>{
						return item.IsOther;
					})
					var obj1 = this.itemData.QuestionnaireTopicOptionDtoList.filter(item=>{
						return !item.IsOther;
					})
					
					obj1.push(...array)
					obj1.push(...obj)
					
					this.itemData.QuestionnaireTopicOptionDtoList = obj1;
					this.visiblePop = false;
				}
				
				// if (dt.length == list.length){
				// 	this.$message.error('请至少添加一项')
				// }else{
				// 	var array = []
				// 	list.map(item=>{
				// 		var str = item.trim();
						
				// 		if (str !== '') {
				// 			if (str.length > 500) {
				// 				str = str.substr(0, 500)
				// 			}
							
				// 			var arr = this.itemData.QuestionnaireTopicOptionDtoList.filter(o=>{
				// 				return o.OptionDescription == str;
				// 			})
							
				// 			array.push({
				// 				Id: arr.length ? arr[0].Id : 0,
				// 				OptionDescription: str,
				// 				IsOther: false,
				// 				Sort: 0
				// 			})
				// 		}
				// 	})
					
				// 	var obj = this.itemData.QuestionnaireTopicOptionDtoList.filter(item=>{
				// 		return item.IsOther;
				// 	})
					
				// 	this.itemData.QuestionnaireTopicOptionDtoList = array.concat(obj);
				// 	this.visiblePop = false;
				// }
				
				// console.log(this.itemData)
			},
			addOther(){
				this.itemData.QuestionnaireTopicOptionDtoList.push({
					Id: 0,
					OptionDescription: '其它',
					IsOther: true,
					Sort: 0
				})
				this.$forceUpdate()
				console.log(this.itemData.QuestionnaireTopicOptionDtoList)
			},
			handleDeleteItem(index){
				this.itemData.QuestionnaireTopicOptionDtoList.splice(index, 1)
			}
		}
	}
</script>

<style lang="less">
.radio-module{
	border: 1px solid #DCDFE6;
	border-radius: 4px;
	padding: 30px 30px 20px;
	font-size: 14px;
	
	.title{
		display: flex;
		align-items: center;
		overflow: hidden;
		
		.num{
			flex: 0 0 auto;
			margin-right: 5px;
		}
		.input{
			flex: 1 1 auto;
			margin-right: 20px;
			border: 1px dashed #ddd;
			min-height: 30px;
			padding-top: 4px;
			
			textarea{
				height: 36px;
				border: none;
				padding: 0 10px;
				width: 100%;
				font-size: 14px;
				box-sizing: border-box;
				outline:none;
			}
			
		}
		.control{
			flex: 0 0 auto;
			white-space: nowrap;
			display: flex;
			
			.drag{
				color: #60aeff;
				margin-right: 10px;
			}
			.delete{
				color: #f36868;
				cursor: pointer;
			}
		}
	}

	.check-list{
		
		padding-top: 10px;
		
		.check-item{
			position: relative;
			display: flex;
			margin-left: 15px;
			margin-right: 114px;
			// padding: 2px 0 2px 0;
			border: 1px dashed #fff;
			
			&:hover{
				border: 1px dashed #ddd;
				
				.delete-item{
					display: flex;
				}
			}
			
			.check{
				position: absolute;
				left: 10px;
				top: 5px;
				flex: 0 0 auto;
				width: 20px;
				height: 20px;
				border-radius: 100%;
				border: 1px solid #ddd;
				z-index: 1;
				// margin-top: 6px;
			}
			.input{
				flex: 1 1 auto;
				
				.name{
					// padding: 0 10px 0 0;
					width: 100%;
					font-size: 14px;
					box-sizing: border-box;
					outline:none;
					border: none;
				}
				
				textarea{
					width: 100%;
					border: none;
					resize: none;
					padding-left: 38px;
					box-sizing: border-box;
					
					&:focus{
						background-color: #f0f2f5;
					}
				}
				
			}
			
			.delete-item{
				display: none;
				position: absolute;
				right: -39px;
				top: 0;
				width: 38px;
				height: 30px;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				background-color: #fff;
				cursor: pointer;
				color: #f36868;
			}
			
		}
	}
	
	.control-wraper{
		display: flex;
		padding-top: 20px;
		margin-left: 15px;
		
		.btn{
			color: #60aeff;
			margin-right: 30px;
			cursor: pointer;
		}
	}
	
	.pop-content{
		.pop-title{
			font-size: 15px;
		}
		.pop-tip{
			font-size: 12px;
			color: #999;
			margin-top: 10px;
		}
	}
	
}
</style>
