<template>
	<div class="open-module">
		<div class="title">
			<div class="num">{{itemIndex+1}}.</div>
			<div class="input"><el-input type="textarea" :rows="1" :autosize="true" v-model="itemData.TopicDescription" maxlength="500"></el-input></div>
			<div class="control"><div style = "color:#409eff" class="drag">拖拽排序</div><div class="delete" style = "color:rgb(245,108,108)" @click="handleDelete">删除</div></div>
		</div>
		
		<div class="text"></div>
		
		<div class="control-wraper">
			<div class="btn" style = "color:#409eff">
				此题必答
				<el-switch
				  v-model="itemData.IsMustAnswer">
				</el-switch>
			</div>
		</div>
		
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
			};
		},
		computed: {
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
			handleDelete(){
				this.$emit('delete', {item: this.itemData, index: this.itemIndex})
			}
		}
	}
</script>

<style lang="less">
.open-module{
	border: 1px solid #DCDFE6;
	border-radius: 4px;
	padding: 30px 30px 20px;
	font-size: 14px;
	
	.title{
		display: flex;
		align-items: center;
		overflow: hidden;
		
		.num{
			flex: 0 0 auto;
			margin-right: 5px;
		}
		.input{
			flex: 1 1 auto;
			margin-right: 20px;
			border: 1px dashed #ddd;
			min-height: 30px;
			padding-top: 4px;
			
			textarea{
				height: 36px;
				border: none;
				padding: 0 10px;
				width: 100%;
				font-size: 14px;
				box-sizing: border-box;
				outline:none;
			}
			
		}
		.control{
			flex: 0 0 auto;
			white-space: nowrap;
			display: flex;
			
			.drag{
				color: #60aeff;
				margin-right: 10px;
			}
			.delete{
				color: #f36868;
				cursor: pointer;
			}
		}
	}

	.text{
		margin: 20px 0 0 15px;
		width: 400px;
		height: 100px;
		border-radius: 4px;
		border: 1px solid #ddd;
	}

	.control-wraper{
		display: flex;
		padding-top: 20px;
		margin-left: 15px;
		
		.btn{
			color: #60aeff;
			margin-right: 30px;
			cursor: pointer;
		}
	}
	
}
</style>
