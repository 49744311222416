<template>
	<div class="scoreten-view">
		<div class="title-value">
			<div class="num">{{itemIndex+1}}、</div>
			<div class="value">
				{{itemData.TopicDescription}}
				<span class="icon-star" v-if="itemData.IsMustAnswer">*</span>
			</div>
		</div>
		
		<img class="score-img" src="@/assets/img/score-ten.jpg">
		
		<div class="score-description" v-if="itemData.showDescrition">
			<div class="left">{{itemData.UserDefinedDescriptionStart}}</div>
			<div class="right">{{itemData.UserDefinedDescriptionEnd}}</div>
		</div>
		
	</div>
</template>

<script>
	
	export default {
		components: {},
		props:{
			itemData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			itemIndex: 0
		},
		data() {
			return {
			};
		},
		computed: {
		},
		beforeMount() {
			// console.log(this.itemData)
		},
		methods: {
		}
	}
</script>

<style lang="less" scoped>
.scoreten-view{
	
	padding: 13px 0 20px;
	margin: 0 15px;
	font-size: 14px;
	border-bottom: 1px solid #eee;
	
	.title-value{
		display: flex;
		margin-bottom: 15px;
		
		.num{
			flex: 0 0 auto;
		}
		.value{
			flex: 1 1 auto;
			line-height: 18px;
			// min-height: 24px;
			
			.icon-star{
				padding-left: 2px;
				color: #e34242;
				display: inline-block;
				font-size: 20px;
				height: 10px;
				overflow: hidden;
			}
		}
	}
	
	.score-img{
		display: block;
		width: 100%;
	}
	
	.score-description{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;
		color: #999;
		font-size: 12px;
		padding: 0 5px;
		box-sizing: border-box;
	}
	
}
</style>
