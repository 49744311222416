<template>
	<div class="edit-questionnaire">

		<step1 v-show="step===1" :IsAnswered="IsAnswered" :normalData="normalData" ref="step1"></step1>
		<step2 v-show="step===2" :normalData="normalData" ref="step2"></step2>

		<div class="edit-btn" v-if="step === 1">
			<el-button @click="cancelVisible=true">取消</el-button>
			<el-button type="primary" @click="handleNext">下一步</el-button>
		</div>
		<div class="edit-btn" v-if="step === 2">
			<el-button @click="step=1">返回编辑问卷</el-button>
			<el-button type="primary" @click="handleSave">保存</el-button>
		</div>

		<el-dialog title="提示" :visible.sync="cancelVisible" width="30%" center>
			<span>您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelVisible = false">关 闭</el-button>
				<el-button type="primary" @click="sureBack">确认离开</el-button>
			</span> 
		</el-dialog>

	</div>
</template>

<script>
	import step1 from './components/step1.vue';
	import step2 from './components/step2.vue';
	import config from '@/config/index';

	import apiList from '@/api/other';
	
	var menuList = [
		{
			id: 0,
			name: '单选题',
			normalTitle: '请选择一个选项'
		}, {
			id: 1,
			name: '多选题',
			normalTitle: '请选择以下选项（多选）'
		}, {
			id: 2,
			name: '打分题：1-5分',
			normalTitle: '请打分'
		}, {
			id: 3,
			name: '打分题：1-10分',
			normalTitle: '请打分'
		}, {
			id: 4,
			name: '图片上传',
			normalTitle: '请上传图片'
		}, {
			id: 5,
			name: '开放题（文字）',
			normalTitle: '请填写本项内容'
		}, {
			id: 6,
			name: '下拉筛选',
			normalTitle: '请选择下方选项'
		}, {
			id: 7,
			name: '手机号',
			normalTitle: '请填写手机号'
		}
	]

	export default {
		components: {
			step1,
			step2
		},
		data() {
			return {
				step: 1,
				cancelVisible: false,
				iscopy:false,//判断是否为复制新增
				normalData: {},
				
				IsAnswered: false
			};
		},
		computed: {},
		beforeMount() {
			this.id = this.$route.query.id || 0;
			this.virtualId = this.$route.query.virtualId || 0;
			// console.log('message',this.$route.query.message)
			if(this.$route.query.message){
				this.normalData = this.$route.query.message
				this.iscopy = true
			}
			// console.log(this.id)
			if (this.id || this.virtualId){
				this.getData();
				this.validId();
			}
			// console.log(this.$route)
				// console.log(this.iscopy?0:this.id)
		},
		methods: {
			async getData(){
				
				var step1 = this.$refs['step1'];
				var step2 = this.$refs['step2'];
				
				const res = await apiList.questionnairedetail({
					QuestionnaireId: this.id || this.virtualId
				})
				
				if (res.IsSuccess){
					if (this.virtualId){
						res.Result.ActivityCouponList = [];
						res.Result.ActivityWheelSurf = null;
						res.Result.GiftProductList = [];
						res.Result.Point = '';
						res.Result.PrizeType = 0;
						res.Result.IsSendPrize = false;
						res.Result.JumpType = 0;
						res.Result.JumpPageName = '';
						res.Result.JumpPagePath = '';
					}
					
					this.normalData = res.Result;
				}
			},
			async validId(){
				const res = await apiList.questionnairevalite({
					QuestionnaireId: this.id
				})
				
				if (res.IsSuccess){
					this.IsAnswered = res.Result.IsAnswered;
				}
			},
			sureBack() {
				this.$router.replace({
					name: 'questionlist'
				})
			},
			handleNext() {

				var step1 = this.$refs['step1'];
				var step2 = this.$refs['step2'];

				if (!step1.QuestionnaireTopicDtoList.length) {
					this.$message.error('至少添加1个题目');
					return;
				}
				console.log(step1.QuestionnaireTopicDtoList)
				this.step = 2;
			},
			getUrl(record) {
				if (record.type == "classify") {
					return 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					// 全部商品
					if (record.data.id == 1) {
						return 'pages/index/index'
					} else if (record.data.id == 2) {
						return 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						return 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						return 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						return 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						return 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						return 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						return 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						return 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						return 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						return 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						return 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						return 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						return 'pageA/pages/groupShare/groupShare'
					} else if (record.data.id == 15) {
						// 全部信息页
						return 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						return 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						return 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						return 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						return 'pageC/pages/AIGC/home'
					}
				} else if (record.type == "product") {
					return 'pages/detail/detail?id=' + record.data.Id
				} else if (record.type == "custom") {
					return 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					return 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					return 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
				} else if (record.type == 'coupon') {
					return 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record.data.uid || '')
				} else if (record.type == 'help') {
					return 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' + record.data.ActivityFullId
				} else if (record.type == 'group') {
					return 'pages/groupDetail/groupDetail?id=' + record.data.ProductId + '&fullId=' + record.data
						.ActivityFullId
				}else if (record.type == 'questionnaire') {
					return 'pageA/pages/questionnaire/questionnaire?&referer=5&id=' + record.data.Id;
				}else if (record.type == 'taketest') {
					return `pageC/pages/goodnessTest/home?id=` + record.data.Id
				}else if (record.type == 'contentgroup') {
					return `pageA/pages/information/informationGroup?id=` + record.data.Id;
				}else if (record.type == 'singlecontent') {
					return `pageA/pages/information/detail?id=` + record.data.Id;
				}else if (record.type == 'helpPro') {
					return `pageC/pages/helpPro/helpPro?id=` + record.data.ProductId + '&activityId=' + record.data.ActivityFullId
				}
			},
			handleSave() {

				var step1 = this.$refs['step1'];
				var step2 = this.$refs['step2'];

				if (step2.JumpType == 1 && !step2.selectPage && !step2.JumpPagePath) {
					this.$message.error('请选择提交成功后跳转的指定页面');
					document.querySelector('.step2-questionnaire').scrollTop = 0;
					return;
				}
				if (step2.IsSendPrize) {
					if (!step2.PrizeType){
						this.$message.error('请至少配置1种奖品');
						return;
					}
					
					// if (!step2.validForm()) return;
					step2.validForm().then(res=>{
						this.beforeSave()
					})
				}else{
					this.beforeSave()
				}
				
				
				
			},
			beforeSave(){
				
				var step1 = this.$refs['step1'];
				var step2 = this.$refs['step2'];
				
				var url = null;
				if (step2.JumpType == 1 && step2.selectPage) {
					url = this.getUrl(step2.selectPage);
					console.log(url)
				}
				
				var params = {
					Id: this.iscopy?0:this.id,
					Poster: step1.Poster,
					Title: step1.Title.trim()==='' ? '问卷标题':step1.Title.trim(),
					Explanation: step1.Explanation.trim()==='' ? '感谢您愿意花费几分钟时间参与我们的调查问卷，现在我们就马上开始吧！':step1.Explanation.trim(),
					QuestionnaireTopicDtoList: step1.QuestionnaireTopicDtoList.map((item, index) => {
						
						if (item.TopicDescription.trim() === ''){
							item.TopicDescription = menuList.filter(o=>{
								return o.id == item.TopicType;
							})[0].normalTitle;
						}
						
						item.QuestionnaireTopicOptionDtoList.map((obj, i) => {
							
							if (obj.OptionDescription.trim() === ''){
								obj.OptionDescription = obj.IsOther ? '其它' : '选项' + (i+1)
							}
							
							obj.Sort = i;
							return obj;
						})
						item.Sort = index;
						
						if (item.TopicType === 2 || item.TopicType === 3){
							if (!item.showDescrition){
								item.UserDefinedDescriptionStart = '';
								item.UserDefinedDescriptionEnd = '';
							}else{
								if (item.UserDefinedDescriptionStart.trim() === ''){
									item.UserDefinedDescriptionStart = '1.非常不满意';
									item.UserDefinedDescriptionEnd = item.TopicType === 3 ? '10.非常满意' : '5.非常满意';
								}
							}
						}
						
						return item;
					}),
					JumpType: step2.JumpType,
					JumpPagePath: url || step2.JumpPagePath || 'pageA/pages/questionnaire/success',
					JumpPageName: step2.selectPage ? step2.selectPage.text : (step2.JumpPageName || '提交成功页'),
					IsSendPrize: step2.IsSendPrize,
					PrizeType: step2.PrizeType,
					Point: step2.Point,
					ActivityWheelSurfId: step2.GiftdetailData.length ? step2.GiftdetailData[0].Id : null,
					SendActivityWheelSurfCount: step2.GiftdetailData.length ? step2.GiftdetailData[0].num : 0,
					ActivityCouponList: step2.Coupons.map(item => {
						return {
							ActivityCouponId: item.Id,
							SendActivityCouponCount: item.num
						}
					}),
					GiftProductList: step2.Gifts.map(item => {
						return {
							GiftProductId: item.ProductId,
							GiftProductSpecId: item.ProductSpecId,
							SendGiftProductCount: item.num
						}
					}),
					IsCanRepeatSubmit:step2.IsCanRepeatSubmit,
				}
				
				// console.log(params)
				this.sureSave(params);
			},
			async sureSave(params) {
				// console.log(apiList)
				const res = await apiList.questionnairesave(params);
				if (res.IsSuccess) {
					this.$router.replace({
						name: 'questionlist'
					})
				} else {
					this.$message.error(res.Message);
				}
			}
		}
	}
</script>

<style lang="less">
	.edit-questionnaire {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;
		font-size: 14px;
		word-break: break-all;

		.edit-btn {
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60px;
			background-color: #fff;
			border-top: 1px solid #ddd;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
</style>
