<template>
	<div class="step2-questionnaire">
		<div class="step2-title"><img src="@/assets/img/questionnaire/questionnaire-6.png">问卷提交成功配置</div>
		<div class="step2-page-info">
			<div class="step2-info-title">多次提交问卷：</div>
			<div class="page-radio">
				<el-radio-group v-model="IsCanRepeatSubmit">
					<el-radio :label="true">开启</el-radio>
					<el-radio :label="false">关闭</el-radio>
				</el-radio-group>
				<div class="tips">开启后，同1个客户可多次提交问卷，若提交问卷发放奖品，多次提交即可获得多次奖品</div>
			</div>
		</div>
		<div class="step2-page-info">
			<div class="step2-info-title">问卷提交成功后跳转至：</div>
			<div class="page-radio">
				<div class="page-radio-item">
					<el-radio v-model="JumpType" :label="0">系统默认提交成功页</el-radio>
				</div>
				<div class="page-radio-item">
					<el-radio v-model="JumpType" :label="1">指定页面</el-radio>
					<div class="select-page" @click="shopVisible=true" v-if="selectPage">{{selectPage.text}}<div class="arrow"></div></div>
					<div class="select-page" @click="shopVisible=true" v-else-if="JumpPageName">{{JumpPageName}}<div class="arrow"></div></div>
					<div class="select-page" @click="shopVisible=true" v-else>请选择链接页面<div class="arrow"></div></div>
				</div>
			</div>
		</div>
		
		<div class="step2-page-info">
			<div class="step2-info-title">问卷提交成功后发放奖品：</div>
			<div class="page-radio">
				<el-radio v-model="IsSendPrize" :label="true">开启</el-radio>
				<el-radio v-model="IsSendPrize" :label="false">关闭</el-radio>
			</div>
		</div>
		<div class="step2-page-info" v-show="IsSendPrize">
			<div class="step2-info-title">奖品类型：</div>
			<div class="page-radio">
				<div class="page-radio-item" style="margin-bottom: 30px;">
					<el-radio v-model="PrizeType" :label="1">
						送
						<el-input v-model="Point" @input="pointChange" maxlength="5" :disabled="PrizeType!==1?true:false" size="mini" style="margin: 0 5px;width: 120px;"
						 onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
						 onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>
						个积分
					</el-radio>
					<div class="error" style = "color:rgb(245,108,108)" v-if="showPointError">积分请设置在1~99999之间，整数</div>
				</div>
				<div class="page-radio-item" style="margin-bottom: 30px;display: block;">
					<div class="radio-wraper">
						<el-radio v-model="PrizeType" :label="2">转盘抽奖</el-radio>
						<div class="select-activity" @click="selectactiveDialog=true" v-if="PrizeType===2">
							<span v-if="GiftdetailData.length">重新选择抽奖活动</span>
							<span style = "color:#409EFF" v-else>选择抽奖活动</span>
						</div>
					</div>
					<activity-table ref="activityTable" v-show="PrizeType===2" :GiftdetailData="GiftdetailData"></activity-table>
					<div class="error" style = "color:rgb(245,108,108)" v-if="showActivityError">请选择抽奖活动</div>
				</div>
				<div class="page-radio-item" style="margin-bottom: 30px;display: block;">
					<div class="radio-wraper">
						<el-radio v-model="PrizeType" :label="3">优惠券</el-radio>
						<div class="select-activity" @click="selectCouponShow=true" v-if="PrizeType===3">选择优惠券</div>
					</div>
					<div class="radio-tip" v-show="PrizeType===3">若优惠券数量少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充</div>
					<coupon-table ref="couponTable" v-show="PrizeType===3" :coupons="Coupons" @refresh="couponDataChange"></coupon-table>
					<div class="error" style = "color:rgb(245,108,108)" v-if="showCouponError">请选择赠送的优惠券</div>
				</div>
				<div class="page-radio-item" style="margin-bottom: 30px;display: block;">
					<div class="radio-wraper">
						<el-radio v-model="PrizeType" :label="4">赠品</el-radio>
						<div class="select-activity" @click="selectGiftShow=true" v-if="PrizeType===4">选择赠品</div>
					</div>
					<div class="radio-tip" v-show="PrizeType===4">若商品库存少于赠送数量，将不再发放赠品。请关注商品库存并及时补充</div>
					<gift-table ref="giftTable" v-show="PrizeType===4" :gifts="Gifts" @refresh="giftDataChange"></gift-table>
					<div class="error" style = "color:rgb(245,108,108)" v-if="showGiftError">请选择赠品</div>
				</div>
			</div>
		</div>
		
		
		<link-modal :visible="shopVisible" :showTab="[3,2,1,4,7,11,12,13]" :showLiveTab="true" @change="dataChange" @cancel="shopVisible=false"></link-modal>
		
		<el-dialog title="选择活动" :visible.sync="selectactiveDialog" width="1100px" class="dialog">
			<turntableActivities :visible.sync="selectactiveDialog" :checkShowId="GiftdetailData.length ? GiftdetailData[0].Id : 0" :selectActivedata="GiftdetailData"
			 @getActivetList="getActivetList" v-if="selectactiveDialog"></turntableActivities>
		</el-dialog>
		
		<!-- 选择优惠券 -->
		<!-- <el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
			<select-coupon api="activityMarkdownGiftChoseCoupon" :couponData="couponData" :params="['CouponName', 'ActivityCouponUseType']"
			 :selectedData="Coupons" @getSelectList="getSelectCouponList" :visible.sync="selectCouponShow"
			 v-if="selectCouponShow"></select-coupon>
		</el-dialog> -->
		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1000px" class="dialog data-dialog">
			<select-coupon
				api="activitycouponpopupList"
				:selectedData="Coupons"
				:params="['KeyWord', 'UseType','','','IsUse']"
				@getSelectList="getSelectCouponList"
				:visible.sync="selectCouponShow"
				:isUse="true"
				v-if="selectCouponShow"
			></select-coupon>
		</el-dialog>
		
		<!-- 选择赠品 -->
		<el-dialog title="选择赠品" :visible.sync="selectGiftShow" width="1300px" class="dialog">
			<select-gift api="productSpecPopList" :couponData="couponData" :isShowSpecValue='true' :params="['KeyWords', 'ProductType', 'ProductGroupId', 'ProductBrandId', 'OnlyChoosable','ActivityCouponId','AcctivityRangeType','IsGift']"
			 :selectedData="Gifts" @getSelectList="getSelectGiftList" :visible.sync="selectGiftShow"
			 v-if="selectGiftShow"></select-gift>
		</el-dialog>
		
	</div>
</template>

<script>
	
	import linkModal from '@/views/components/linkModal';
	
	import turntableActivities from '@/views/market/payGifts/turntableActivities.vue';
	import activityTable from './activityTable/activityTable.vue';
	
	import selectCoupon from '@/components/SelectMultipCoupon';
	import couponTable from './couponTable/couponTable.vue';
	
	import selectGift from '@/views/market/discountActivity/SelectMulGift';
	import giftTable from './giftTable/giftTable.vue';
	
	export default {
		components: {
			linkModal,
			turntableActivities,
			activityTable,
			selectCoupon,
			couponTable,
			selectGift,
			giftTable,
		},
		data() {
			return {
				JumpType: 0, // 0系统页面  1指定页面
				shopVisible: false,
				selectPage: null,
				JumpPageName: '',
				JumpPagePath: '',
				
				IsSendPrize: false,
				PrizeType: 0,
				Point: '',
				showPointError: false,
				
				selectactiveDialog: false,
				checkShowId: 0,
				GiftdetailData: [],
				showActivityError: false,
				
				selectCouponShow: false,
				couponData: {},
				Coupons: [],
				showCouponError: false,
				
				
				selectGiftShow: false,
				couponData: {},
				Gifts: [],
				showGiftError: false,

				IsCanRepeatSubmit:false,
				
			};
		},
		props: {
			normalData: {
				type: Object,
				default: ()=>{
					return {}
				}
			}
		},
		watch: {
			normalData(){
				if (this.normalData.Id){
					this.initData();
				}
			}
		},
		beforeMount() {
			// console.log(this.QuestionnaireTopicDtoList)
			if (this.normalData.Id){
				this.initData();
			}
		},
		methods: {
			initData(){
				
				var obj = JSON.parse(JSON.stringify(this.normalData));
				
				this.JumpType = obj.JumpType;
				this.IsSendPrize = obj.IsSendPrize;
				this.PrizeType = obj.PrizeType;
				this.Point = obj.Point || '';
				this.IsCanRepeatSubmit = obj.IsCanRepeatSubmit;
				
				if (this.JumpType === 1 && obj.JumpPageName && obj.JumpPagePath){
					this.JumpPageName = obj.JumpPageName;
					this.JumpPagePath = obj.JumpPagePath;
				}
				
				if (this.PrizeType === 2 && obj.ActivityWheelSurf){
					obj.ActivityWheelSurf.Name = obj.ActivityWheelSurf.ActivityName;
					obj.ActivityWheelSurf.num = obj.ActivityWheelSurf.SendActivityWheelSurfCount;
					
					obj.ActivityWheelSurf.ActivityWheelSurfDelstatus = obj.ActivityWheelSurf.Delstatus;
					
					this.GiftdetailData = obj.ActivityWheelSurf ? [obj.ActivityWheelSurf] : [];
				}
				if (this.PrizeType === 3 && obj.ActivityCouponList){
					this.Coupons = obj.ActivityCouponList.map(item=>{
						item.Remark = item.IsFailure?'已失效':item.LeaveCount< 1? '数量=0': '';
						item.num = item.SendActivityCouponCount;
						return item;
					});
				}
				if (this.PrizeType === 4 && obj.GiftProductList){
					this.Gifts = obj.GiftProductList.map(item=>{
						item.Remark = !item.IsOpen ?'仓库中':item.Stock< 1? '已售罄': '';
						item.ProductId = item.Id;
						item.num = item.SendGiftProductCount;
						return item;
					});
				}
				
			},
			dataChange(record) {
				this.selectPage = record;
			},
			getActivetList(data) {
				if (data.Id){
					data.num = 1;
					this.GiftdetailData = []
					this.GiftdetailData.push(data)
					this.checkShowId = data.Id;
					this.showActivityError = false;
				}
				this.selectactiveDialog = false;
			},
			
			//获取选择的优惠券列表
			getSelectCouponList(data) {
				console.log(data)
				var ids = this.Coupons.map(item=>{
					return item.Id;
				})
				var num = this.Coupons.map(item=>{
					return item.num;
				})
				data.map(item=>{
					console.log(ids.indexOf(item.Id))
					if (ids.indexOf(item.Id)>-1){
						item.num = num[ids.indexOf(item.Id)];
					}else{
						item.num = 1;
					}
					return item;
				})
				
				this.Coupons = data;
				this.selectCouponShow = false;
				this.showCouponError = false;
			},
			couponDataChange(list){
				this.Coupons = list;
			},
			
			getSelectGiftList(data){
				var ids = this.Gifts.map(item=>{
					return item.ProductSpecId;
				})
				var num = this.Gifts.map(item=>{
					return item.num;
				})
				data.map(item=>{
					console.log(ids.indexOf(item.ProductSpecId))
					if (ids.indexOf(item.ProductSpecId)>-1){
						item.num = num[ids.indexOf(item.ProductSpecId)];
					}else{
						item.num = 1;
					}
					return item;
				})
				
				this.Gifts = data;
				this.selectGiftShow = false;
				this.showGiftError = false;
			},
			giftDataChange(list){
				this.Gifts = list;
			},
			
			
			pointChange(){
				if (this.Point){
					this.showPointError = false;
				}
			},
			
			
			validForm(){
				return new Promise((reslove, reject)=>{
					if (this.PrizeType == 1){
						if ((!(this.Point+'').trim() || (this.Point+'').trim()==='0')){
							this.showPointError = true;
							this.showActivityError = false;
							this.showCouponError = false;
							this.showGiftError = false;
							reject(false);
						}else{
							reslove(true);
						}
					}
					if (this.PrizeType == 2){
						if (!this.GiftdetailData.length){
							this.showPointError = false;
							this.showActivityError = true;
							this.showCouponError = false;
							this.showGiftError = false;
							reject();
						}else{
							this.$refs['activityTable'].validForm().then(res=>{
								reslove();
							}).catch(err=>{
								reject();
							})
						}
						
					}
					if (this.PrizeType == 3){
						if(!this.Coupons.length){
							this.showPointError = false;
							this.showActivityError = false;
							this.showCouponError = true;
							this.showGiftError = false;
							reject(false);
						}else{
							this.$refs['couponTable'].validForm().then(res=>{
								reslove(true);
							}).catch(err=>{
								reject(false);
							})
						}
					}
					if (this.PrizeType == 4){
						
						if (!this.Gifts.length){
							this.showPointError = false;
							this.showActivityError = false;
							this.showCouponError = false;
							this.showGiftError = true;
							reject();
						}else{
							this.$refs['giftTable'].validForm().then(res=>{
								reslove();
							}).catch(err=>{
								reject();
							})
						}
					}
				})
				
			}
			
		}
	}
</script>

<style lang="less" scoped>
.tips{
	font-size: 12px;
	color: #999;
	// margin-left: 24px;
	margin-top: 10px;
}
.step2-questionnaire{
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 60px;
	box-sizing: border-box;
	background-color: #fff;
	font-size: 14px;
	word-break: break-all;
	padding: 40px;
	overflow: auto;
	
	.step2-title{
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 40px;
		
		img{
			width: 20px;
			margin-right: 5px;
		}
	}
	
	.step2-page-info{
		display: flex;
		margin-bottom: 20px;
		
		.step2-info-title{
			flex: 0 0 auto;
			width: 180px;
			text-align: right;
			font-weight: bold;
		}
		.page-radio{
			flex: 1 1 auto;
			margin-left: 15px;
		}
		.page-radio-item{
			position: relative;
			margin-bottom: 10px;
			display: flex;
			
			.radio-wraper{
				display: flex;
			}
			
			.select-page{
				margin-left: -10px;
				margin-bottom: 1px;
				color: #409EFF;
				display: flex;
				align-items: center;
				cursor: pointer;
				
				.arrow{
					width: 7px;
					height: 7px;
					margin-left: 5px;
					margin-bottom: 3px;
					border-left: 1px solid #409EFF;
					border-bottom: 1px solid #409EFF;
					transform: rotate(-45deg);
				}
			}
			
			.seed-point{
				display: flex;
				align-items: center;
			}
			
			.select-activity{
				color: #409EFF;
				margin-left: -10px;
				cursor: pointer;
			}
			.radio-tip{
				font-size: 12px;
				color: #999;
				margin-left: 24px;
				margin-top: 10px;
			}
		}
	}
	
	.error{
		position: absolute;
		bottom: -20px;
		margin-left: 26px;
		font-size: 12px;
		color: red;
	}
}
</style>
