<template>
	<div class="step1-questionnaire">
		
		<div class="menu-wraper">
			<div class="menu-item" v-for="(item, index) in menuList" :key="item.id" @click="handleAddItem(item, index)">
				<img v-if="item.id === 0" src="@/assets/img/questionnaire/questionnaire-2.png" />
				<img v-if="item.id === 1" src="@/assets/img/questionnaire/questionnaire-4.png" />
				<img v-if="item.id === 2" src="@/assets/img/questionnaire/questionnaire-1.png" />
				<img v-if="item.id === 3" src="@/assets/img/questionnaire/questionnaire-3.png" />
				<img v-if="item.id === 4" src="@/assets/img/questionnaire/questionnaire-7.png" />
				<img v-if="item.id === 5" src="@/assets/img/questionnaire/questionnaire-8.png" />
				<img v-if="item.id === 6" src="@/assets/img/questionnaire/questionnaire-12.png" />
				<img v-if="item.id === 7" src="@/assets/img/questionnaire/questionnaire-5.png" />
				{{item.name}}
			</div>
		</div>

		<div class="content-wraper">
			<div class="content-title">
				<img src="@/assets/img/questionnaire/questionnaire-10.png">问卷海报
				<div class="chear-img" @click="Poster=''">清空图片</div>
			</div>
			<el-upload class="banner-img" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload">
					<i slot="default" v-if="!Poster" class="el-icon-plus"></i>
					<img v-else :src="imgUrl + Poster" />
			</el-upload>
			<div class="content-title"><img src="@/assets/img/questionnaire/questionnaire-9.png">问卷标题与说明</div>
			<div class="title-and-tip">
				<el-input v-model="Title" type="text" style="margin-bottom: 10px;" maxlength="100" placeholder="问卷标题">
				</el-input>
				<el-input v-model="Explanation" type="textarea" maxlength="1000" :rows="4"
					placeholder="感谢您愿意花费几分钟时间参与我们的调查问卷，现在我们就马上开始吧！"></el-input>
			</div>
			<div class="content-title"><img src="@/assets/img/questionnaire/questionnaire-11.png">问卷内容</div>
			<div class="no-date-content" v-if="!QuestionnaireTopicDtoList.length"><img src="@/assets/img/questionnaire/questionnaire-13.png" />点击左侧问题类型添加题目</div>


			<draggable :list="QuestionnaireTopicDtoList" :options="{animation: 100, handle:'.quesition-item'}"
				:scroll="true" scrollSensitivity="500">
				<transition-group>
					<div class="quesition-item" v-for="(item, index) in QuestionnaireTopicDtoList" :key="index">
						<!-- 单选题 -->
						<radio-module v-if="item.TopicType === 0" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></radio-module>

						<!-- 多选 -->
						<checkbox-module v-if="item.TopicType === 1" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></checkbox-module>

						<!-- 打分-5分 -->
						<score-five v-if="item.TopicType === 2" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></score-five>

						<!-- 打分-10分 -->
						<score-ten v-if="item.TopicType === 3" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></score-ten>

						<!-- 上传图片 -->
						<upload-module v-if="item.TopicType === 4" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></upload-module>

						<!-- 开放题（文字） -->
						<open-module v-if="item.TopicType === 5" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></open-module>

						<!-- 下拉筛选 -->
						<dropdown-module v-if="item.TopicType === 6" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></dropdown-module>

						<!-- 手机号 -->
						<phone-module v-if="item.TopicType === 7" :itemData="item" :itemIndex="index"
							@delete="handleDelete"></phone-module>
					</div>
				</transition-group>
			</draggable>

		</div>

		<div class="brower">
			<div class="phone-view">
				<img class="phone-view-head" src="@/assets/img/1621647427.png">
				<div class="phone-view-content">
					<div class="view-poster" v-if="Poster">
						<img :src="imgUrl + Poster"/>
					</div>
					<div class="view-title">{{Title || '问卷标题'}}</div>
					<div class="view-explanation">{{Explanation || '感谢您愿意花费几分钟时间参与我们的调查问卷，现在我们就马上开始吧！'}}</div>
					<div>
						<div class="phone-view-item" v-for="(item, index) in QuestionnaireTopicDtoList" :key="index">
							<radio-view v-if="item.TopicType === 0" :itemData="item" :itemIndex="index"></radio-view>
							<checkbox-view v-if="item.TopicType === 1" :itemData="item" :itemIndex="index"></checkbox-view>
							<scorefive-view v-if="item.TopicType === 2" :itemData="item" :itemIndex="index"></scorefive-view>
							<scoreten-view v-if="item.TopicType === 3" :itemData="item" :itemIndex="index"></scoreten-view>
							<upload-view v-if="item.TopicType === 4" :itemData="item" :itemIndex="index"></upload-view>
							<open-view v-if="item.TopicType === 5" :itemData="item" :itemIndex="index"></open-view>
							<dropdown-view v-if="item.TopicType === 6" :itemData="item" :itemIndex="index"></dropdown-view>
							<phone-view v-if="item.TopicType === 7" :itemData="item" :itemIndex="index"></phone-view>
						</div>
					</div>
					
					<div class="submit-btn">提交</div>
					
				</div>
			</div>
			
		</div>
		
		
	</div>
</template>

<script>
	
	var menuList = [{
		id: 0,
		name: '单选题',
		normalTitle: '请选择一个选项'
	}, {
		id: 1,
		name: '多选题',
		normalTitle: '请选择以下选项（多选）'
	}, {
		id: 2,
		name: '打分题：1-5分',
		normalTitle: '请打分'
	}, {
		id: 3,
		name: '打分题：1-10分',
		normalTitle: '请打分'
	}, {
		id: 4,
		name: '图片上传',
		normalTitle: '请上传图片'
	}, {
		id: 5,
		name: '开放题（文字）',
		normalTitle: '请填写本项内容'
	}, {
		id: 6,
		name: '下拉筛选',
		normalTitle: '请选择下方选项'
	}, {
		id: 7,
		name: '手机号',
		normalTitle: '请填写手机号'
	}]

	import radioModule from './radio/radio.vue';
	import checkboxModule from './checkbox/checkbox.vue';
	import scoreFive from './scoreFive/scoreFive.vue';
	import scoreTen from './scoreTen/scoreTen.vue';
	import uploadModule from './upload/upload.vue';
	import openModule from './open/open.vue';
	import dropdownModule from './dropdown/dropdown.vue';
	import phoneModule from './phone/phone.vue';
	
	import radioView from './radio/view.vue';
	import checkboxView from './checkbox/view.vue';
	import scorefiveView from './scoreFive/view.vue';
	import scoretenView from './scoreTen/view.vue';
	import uploadView from './upload/view.vue';
	import openView from './open/view.vue';
	import dropdownView from './dropdown/view.vue';
	import phoneView from './phone/view.vue';

	import draggable from 'vuedraggable';
	import config from '@/config/index';

	export default {
		components: {
			radioModule,
			checkboxModule,
			scoreFive,
			scoreTen,
			uploadModule,
			openModule,
			dropdownModule,
			phoneModule,
			
			radioView,
			checkboxView,
			scorefiveView,
			scoretenView,
			uploadView,
			openView,
			dropdownView,
			phoneView,

			draggable
		},
		data() {
			return {
				menuList: menuList,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				
				step: 1,

				Poster: '', //海报
				Title: '', //问卷标题
				Explanation: '', //问卷说明
				QuestionnaireTopicDtoList: [], //问卷内容

			};
		},
		props: {
			normalData: {
				type: Object,
				default: ()=>{
					return {}
				}
			},
			IsAnswered: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			normalData(){
				if (this.normalData.Id){
					this.initData();
				}
			}
		},
		beforeMount() {
			// console.log(this.QuestionnaireTopicDtoList)
			if (this.normalData.Id){
				this.initData();
			}
		},
		methods: {
			initData(){
				
				var obj = JSON.parse(JSON.stringify(this.normalData));
				
				this.Poster = obj.Poster;
				this.Title = obj.Title;
				this.Explanation = obj.Explanation;
				this.QuestionnaireTopicDtoList = obj.QuestionnaireTopicDtoList.map(item=>{
					if (item.TopicType === 2 || item.TopicType === 3){
						if (item.UserDefinedDescriptionStart){
							item.showDescrition = true;
						}else{
							if (item.TopicType === 2){
								item.UserDefinedDescriptionStart = '1.非常不满意';
								item.UserDefinedDescriptionEnd = '5.非常满意';
							}else{
								item.UserDefinedDescriptionStart = '1.非常不满意';
								item.UserDefinedDescriptionEnd = '10.非常满意';
							}
						}
					}
					return item;
				});
			},
			handleAvatarSuccess(res, file) {
				this.Poster = res[0];
			},
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isLt2M) {
					this.$message.error('问卷海报图片请控制在2M及以内');
				}
				return isLt2M;
			},
			handleAddItem(item, index) {

				var start = '';
				var end = '';
				if (item.id == 2) {
					start = '1.非常不满意';
					end = '5.非常满意'
				}
				if (item.id == 3) {
					start = '1.非常不满意';
					end = '10.非常满意'
				}

				this.QuestionnaireTopicDtoList.push({
					Id: 0,
					TopicType: item.id,
					TopicTypeValue: '',
					TopicDescription: item.normalTitle,
					UserDefinedDescriptionStart: start,
					UserDefinedDescriptionEnd: end,
					showDescrition: false,
					QuestionnaireTopicOptionDtoList: [{
							Id: 0,
							OptionDescription: '选项1',
							IsOther: false,
							Sort: 0
						},
						{
							Id: 0,
							OptionDescription: '选项2',
							IsOther: false,
							Sort: 0
						}
					],
					IsMustAnswer: false,
					Sort: index
				})
			},
			handleDelete(e) {
				
				this.$confirm('删除后不可恢复，是否确认删除题目?', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					
					if (this.IsAnswered){
						this.$confirm('该题目已经有用户填写，删除后该题目统计到的数据将无法继续查看，是否确认删除？', '提示', {
							confirmButtonText: '确认删除',
							cancelButtonText: '关闭',
							type: 'warning'
						}).then(() => {
							this.QuestionnaireTopicDtoList.splice(e.index, 1)
						})
					}else{
						this.QuestionnaireTopicDtoList.splice(e.index, 1)
					}
					
				})
				
				
			}
		}
	}
</script>

<style lang="less">
	.step1-questionnaire {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 60px;
		box-sizing: border-box;
		display: flex;
		background-color: #fff;
		font-size: 14px;
		word-break: break-all;

		.menu-wraper {
			flex: 0 0 auto;
			width: 200px;
			padding: 30px 0 30px 20px;
			border-right: 1px solid #ddd;
			overflow: auto;

			.menu-item {
				width: 160px;
				height: 40px;
				border: 1px solid #ddd;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				padding-left: 10px;
				font-size: 14px;
				cursor: pointer;

				img {
					display: block;
					width: 20px;
					margin-right: 5px;
				}
			}
		}

		.brower {
			flex: 0 0 auto;
			width: 450px;
			padding: 60px 30px;
			box-sizing: border-box;
			display: flex;
			overflow: hidden;
			
			.phone-view{
				width: 100%;
				max-height: 800px;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				
				.phone-view-head{
					flex: 0 0 auto;
					width: 100%;
					height: auto;
				}
				.phone-view-content{
					flex: 1 1 auto;
					// margin-top: -1px;
					background-color: #fff;
					border: 9px solid #f0f2f5;
					border-top: none;
					box-sizing: border-box;
					margin-left: 1px;
					border-radius: 0 0 20px 20px;
					overflow: auto;
				}
			}
			
			
			.view-poster{
				display: block;
				width: 100%;
				max-height: 150px;
				overflow: hidden;
				
				img{
					display: block;
					width: 100%;
				}
			}
			.view-title{
				font-size: 17px;
				text-align: center;
				padding: 15px 30px;
				font-weight: bold;
			}
			.view-explanation{
				font-size: 12px;
				color: #666;
				margin: 0 15px 10px;
				padding-bottom: 15px;
				line-height: 16px;
				border-bottom: 1px solid #eee;
			}
			
			.submit-btn{
				width: 200px;
				height: 36px;
				line-height: 36px;
				border-radius: 6px;
				color: #fff;
				font-size: 15px;
				background-color: #53a8ff;
				text-align: center;
				margin: 50px auto;
			}
			
		}

		.content-wraper {

			flex: 1 1 auto;
			padding: 0 30px;
			overflow: auto;

			.content-title {
				position: relative;
				display: flex;
				align-items: center;
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 10px;
				margin-top: 30px;

				img {
					display: block;
					width: 16px;
					margin-right: 5px;
				}
				
				.chear-img{
					position: absolute;
					right: 0;
					top: 0;
					font-size: 14px;
					color: #409EFF;
					cursor: pointer;
				}
			}

			.banner-img {
				width: 100%;
				// max-width: 400px;
				height: 200px;
				border: 1px dashed #c0ccda;
				border-radius: 6px;
				box-sizing: border-box;
				
				>div{
					width: 100%;
					height: 198px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 6px;
					overflow: hidden;
				}

				.el-icon-plus {
					font-size: 40px;
					color: #999;
				}

				img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			.title-and-tip {
				border: 1px solid #ddd;
				border-radius: 6px;
				padding: 20px;

				input {
					width: 100%;
					border: 1px dashed #ddd;
					border-radius: 4px;
					height: 36px;
					margin-bottom: 20px;
					font-size: 14px;
					padding: 0 10px;
					box-sizing: border-box;
				}

				textarea {
					width: 100%;
					border: 1px dashed #ddd;
					height: 100px;
					padding: 10px;
					box-sizing: border-box;
				}
			}

			.no-date-content {
				height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 15px;
				border: 1px solid #ddd;
				border-radius: 4px;

				img {
					display: block;
					width: 20px;
					margin-right: 10px;
				}
			}
		}

		.quesition-item {
			margin-bottom: 20px;
		}

	}
</style>
