<template>
	<!-- <div class="activity-table">
		
	</div> -->
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">

		<el-table :data="ruleForm.list" style="width:700px;margin:15px 0 15px 22px" v-loading="loading"
			ref="multipleTable" :row-key="rowKeys">
			<el-table-column prop="Name" label="活动">
				<template slot-scope="scope">
					<div class="towLines">{{scope.row.Name}}</div>
					<div style="color: red;margin-left: 5px;">
						{{(scope.row.ActivityStateShow=='进行中'||scope.row.ActivityStateShow=='未开始') ? '' : scope.row.ActivityStateShow}}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="RoomId" label="活动时间" width="200px">
				<template slot-scope="scope">
					<div>{{scope.row.StartTime}} 至</div>
					{{scope.row.EndTime}}
				</template>
			</el-table-column>

			<el-table-column prop="RoomId" label="赠送次数" width="210px">
				<template slot-scope="scope">
					<el-form-item label="" :prop="'list.' + scope.$index + '.num'" :rules="rules.numRule">
						<el-input-number :min="1" :max="10" :value="scope.row.num" @change="(val)=>numChange(val, scope.$index)" :precision="0" :controls="false"
							style="width: 130px;"></el-input-number>
					</el-form-item>
				</template>
			</el-table-column>
		</el-table>
	</el-form>


</template>

<script>
	export default {
		components: {},
		props: {
			GiftdetailData: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		data() {
			var check = (rule, value, callback) => {
				console.log(rule, value)
				if (!value) {
					callback(new Error('抽奖次数请设置在1-10之间，整数'));
				}  else {
					callback();
				}
			};
			return {
				loading: false,

				ruleForm: {
					list: []
				},
				rules: {
					numRule: [{
						required: true,
						trigger: 'blur',
						validator: check
					}]
				}
			};
		},
		watch: {
			GiftdetailData() {
				this.ruleForm.list = this.GiftdetailData;
			}
		},
		computed: {},
		beforeMount() {
			this.ruleForm.list = this.GiftdetailData;
		},
		methods: {
			rowKeys(row) {
				return row.Id
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					this.$refs['ruleForm'].validate((valid) => {
						if (valid) {
							resolve('success');
						} else {
							reject('fail')
						}
					});
				})
			},
			numChange(val, index){
				console.log(val, index)
				var record = this.ruleForm.list[index];
				record.num = val;
				
				this.ruleForm.list.splice(index, 1, record)
			}
		}
	}
</script>

<style lang="less" scoped>
	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
</style>
