<template>
	<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px">
		<div style="margin-left: 22px;margin-top: 15px;">
			<el-table border :data="ruleForm.list" style="width: 100%" :row-key="rowKeys">
				<el-table-column :key="14" label="商品" width="300px">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src='imgUrl+scope.row.ImgUrl' />
							<div class="right">
								<div class="name">{{scope.row.Name}}</div>
								<span>{{scope.row.Remark}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column :key="15" prop="ProductSpec" label="规格">
					<template slot-scope="scope">
						<div v-if="scope.row.Spce==''">默认规格</div>
						<div v-else>{{scope.row.Spce}}</div>
					</template>
				</el-table-column>
				<el-table-column :key="16" prop="Price" label="价格"></el-table-column>
				<el-table-column :key="17" prop="Stock" label="库存"></el-table-column>
				<el-table-column label="赠送数量" width="200px">
					<template slot-scope="scope">
						<el-form-item :id="'row-'+scope.$index" label="" :prop="'list.' + scope.$index + '.num'" :rules="rules.numRule">
							<el-input-number :min="1" :max="99" :value="scope.row.num" @change="(val)=>numChange(val, scope.row, scope.$index)" :precision="0" :controls="false"
								style="width: 130px;"></el-input-number>
						</el-form-item>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button style="color:rgb(245,108,108);" @click="deleteSortCoupon(scope.row,scope.$index)" type="text"
						 size="small">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="page" style="overflow: hidden;">
				<el-pagination v-if="total" style="margin-top:10px;margin-right:50px;float:right;" @size-change="handleCouponsSizeChange"
				 @current-change="handleCouponsCurrentChange" :current-page="current" :page-sizes="[5,10, 20]"
				 :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
			
		</div>
	</el-form>
</template>

<script>
	import config from '@/config/index';
	export default {
		components: {
		},
		props:{
			gifts: {
				type: Array,
				default: ()=>{
					return []
				}
			}
		},
		data() {
			
			var check = (rule, value, callback) => {
				console.log(rule, value)
				if (!value) {
					callback(new Error('数量请设置在1-99之间，整数'));
				}  else {
					callback();
				}
			};
			
			return {
				imgUrl: config.IMG_BASE,
				loading: false,
				current: 1,
				size: 5,
				
				list: [],
				
				ruleForm: {
					list: []
				},
				rules: {
					numRule: [{
						required: true,
						trigger: 'blur',
						validator: check
					}]
				}
			};
		},
		computed: {
			total(){
				return this.list.length
			},
			data(){
				
				// var list = JSON.parse(JSON.stringify(this.list));
				// var array = list.splice((this.current-1)*this.size, this.size)
				// console.log(array)
				// if (this.current != 1 && !array.length){
				// 	this.current --;
				// 	return list.splice((this.current-1)*this.size, this.size)
				// }
				
				// return array
			}
		},
		watch:{
			gifts(){
				this.list = this.gifts;
				this.initPageData();
			}
		},
		beforeMount() {
			this.list = this.gifts;
			this.initPageData();
		},
		methods: {
			initPageData(){
				var list = JSON.parse(JSON.stringify(this.list));
				var array = list.splice((this.current-1)*this.size, this.size);
				
				if (this.current != 1 && !array.length){
					this.current = Math.ceil(list.length/this.size);
					array = list.splice((this.current-1)*this.size, this.size)
				}
				this.ruleForm.list = array;
			},
			rowKeys(row) {
				return row.ProductSpecId
			},
			handleCouponsSizeChange(val){
				this.size = val;
				this.initPageData();
			},
			handleCouponsCurrentChange(val){
				this.current = val;
				this.initPageData();
			},
			deleteSortCoupon(record, index){
				var list = JSON.parse(JSON.stringify(this.list));
				list = list.filter(item=>{
					return item.ProductSpecId != record.ProductSpecId;
				})
				this.list = list;
				this.initPageData();
				this.$emit('refresh', this.list)
			},
			validForm() {
				return new Promise((resolve, reject)=>{
					
					var index = -1;
					var dt = null;
					for (var i=0; i<this.list.length; i++){
						if (!this.list[i].num){
							index = i;
							dt = this.list[i]
							break;
						}
					}
					
					
					this.current = Math.ceil(((index+1)/this.size));
					this.initPageData();
					
					this.$nextTick(()=>{
						this.$refs['ruleForm'].validate((valid) => {
							if (valid) {
								resolve('success');
							} else {
								if (dt){
									this.ruleForm.list.map((item, j)=>{
										if (item.Id == dt.Id){
											var dom = document.querySelector('#row-'+j);
											var box = document.querySelector('.step2-questionnaire');
											var realTop = dom.getBoundingClientRect().y + box.scrollTop;
											console.log(dom.getBoundingClientRect().y)
											box.scrollTop = realTop-130;
										}
									})
								}
								reject('fail')
							}
						});
					})
					
				})
			},
			numChange(val, dt, index){
				
				this.list.map(item=>{
					if (item.ProductSpecId == dt.ProductSpecId){
						item.num = val;
					}
					return item;
				})
				
				var record1 = this.ruleForm.list[index];
				record1.num = val;
				this.ruleForm.list.splice(index, 1, record1);
				
				this.$emit('refresh', this.list)
			}
		}
	}
</script>

<style lang="less" scoped>
.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: contain;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}
</style>
